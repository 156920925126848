import axios from "axios";
import { workersSliceActions as worker } from "../../slices/workers";
import { enqueueSnackbar } from "notistack";
import { UISliceActions as UIactions, UISliceActions } from "../../slices/UI2";
import { bkpMailCountId } from "../../../components/UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { bkpDriveSizeId } from "../../../components/UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";
import { m365validated } from "../../../components/UserCloudIntegrations/Components/Buttons/Save";

export const validateMailId = "windows365validations";
// export const w365orgsData = "windows365organizations";

const UIError = {
  "es-CL": {
    error: "Error al validar correo",
    notFound: "El correo no existe en la organización",
    similar: "El correo no existe en la organización, pero se sugiere",
    found: "Correo encontrado en la organización",
  },
  "en-US": {
    error: "Error validating mail",
    notFound: "Mail not found in the organization",
    similar: "Mail not found in the organization, but suggested",
    found: "Mail found in the organization",
  },
};

export const validateW365Mails = (data) => {
  const id = validateMailId;
  const { W365server, cloner_key, tryouts, locale } = data;

  console.log("sugges mails", tryouts);

  return async (dispatch) => {
    dispatch(worker.start(id));
    // dispatch(warehouse.unload(w365orgsData));
    // const group_id = groupId ?? group;

    // let lastError = null;
    for (let attempt of tryouts) {
      console.log("attempt", attempt);
      const newMail = attempt.mail + "@" + attempt.org.domain;
      const url = `${W365server}mailapi/organization/${attempt.org.id}/client/${newMail}`;
      let clientMail = "";
      try {
        console.log("URL", url);
        if (attempt.login === "") {
          console.log("IF1");
          dispatch(
            UIactions.setFieldError({
              id: "365mail-" + attempt.login,
              value: "campo vacío",
            })
          );
          dispatch(UISliceActions.clear(bkpMailCountId + attempt.login));
          dispatch(UISliceActions.clear(bkpDriveSizeId + attempt.login));
        } else {
          // console.log("IF2");
          const response = await axios.get(url, { headers: { cloner_key } });

          const client = response.data.data;
          // console.log("client", client);

          // console.log("response", response.status);

          // console.log("client.mail", client.mail);
          clientMail = client.mail.toLowerCase().split("@")[0];
          // console.log("clientMail", clientMail);

          // console.log("CLIENT", clientMail, attempt.mail);

          if (clientMail === attempt.mail.toLowerCase()) {
            enqueueSnackbar(UIError[locale].found + ": " + newMail, {
              variant: "success",
            });

            let url2 = `${W365server}mailapi/organization/${attempt.org.id}/client/${newMail}/space`;
            const response2 = await axios.get(url2, {
              headers: { cloner_key },
            });
            const data = response2.data.data;
            console.log("response2", response2.data.data);

            dispatch(
              UISliceActions.setValue({
                id: bkpMailCountId + attempt.login,
                value: data.count_mails,
              })
            );
            dispatch(
              UISliceActions.setValue({
                id: bkpDriveSizeId + attempt.login,
                value: data.drive,
              })
            );
            dispatch(
              UISliceActions.setValue({
                id: m365validated,
                value: true,
              })
            )
          } else {
            dispatch(
              UIactions.setFieldError({
                id: "365mail-" + attempt.login,
                value: UIError[locale].similar + ": " + clientMail,
              })
            );
            let warning = `${UIError[locale].similar}: ${clientMail}@${attempt.org.domain}`;
            enqueueSnackbar(warning, { variant: "warning" });
            dispatch(UISliceActions.clear(bkpMailCountId + attempt.login));
            dispatch(UISliceActions.clear(bkpDriveSizeId + attempt.login));
          }
        }
        dispatch(worker.finish({ id }));
      } catch (e) {
        // lastError = e;
        // console.log("error", e);
        dispatch(UISliceActions.clear(bkpMailCountId + attempt.login));
        dispatch(UISliceActions.clear(bkpDriveSizeId + attempt.login));
        if (e.response.status === 404) {
          enqueueSnackbar(UIError[locale].notFound + ": " + newMail, {
            variant: "error",
          });
          dispatch(
            UIactions.setField({
              id: "365mail-" + attempt.login,
              value: "",
            })
          );
          dispatch(
            UIactions.setFieldError({
              id: "365mail-" + attempt.login,
              value: UIError[locale].notFound,
            })
          );
        } else {
          enqueueSnackbar(UIError[locale].error + ": " + newMail, {
            variant: "error",
          });
        }
        // ACA REVISAR SI LAREQUEST FALLO MAL O SI ES QUE NO EXISTE LA ORGANIZACION
        dispatch(worker.finish({ id, error: e.message }));
      }
    }

    dispatch(worker.finish({ id }));
  };
};
