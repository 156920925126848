import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as worker } from "../../slices/workers";
import { getW365ClientId } from "./getClient";

const UIText = {
  "es-CL": {
    success: "Activacion del usuario de Windows365 exitosa",
    error: "Error al activar usuario de Windows365: ",
  },
  "en-US": {
    success: "Windows365 user activation successful",
    error: "Error while activating Windows365 user: ",
  },
};

export const postClientId = "post365client";

const postClient = (data) => {
  const { W365server, cloner_key, payload, locale } = data;
  const id = postClientId;
  const { success, error } = UIText[locale];
  return async (dispatch) => {
    // console.log("postClient payload", payload);
    const bodyData = JSON.stringify(payload);
    // console.log("postClient bodyData", bodyData);
    dispatch(worker.start(id));
    try {
      const url = `${W365server}mailapi/client`;
      await axios.post(url, bodyData, {
        headers: { cloner_key },
      });
      //   console.log("CLIENT?", client);
      // dispatch(UISliceActions.clear(authId));
      enqueueSnackbar(success, { variant: "success" });
      dispatch(worker.finish({ id }));
      dispatch(worker.clear(getW365ClientId));
    } catch (e) {
      console.error(e);
      enqueueSnackbar(error + e.message, { variant: "error" });
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};

export default postClient;
