import axios from "axios";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as worker } from "../../slices/workers";
import { bkpDriveSizeId } from "../../../components/UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";
import { bkpMailCountId } from "../../../components/UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { UISliceActions } from "../../slices/UI2";

export const getW365ClientId = "getW365Client";
export const w365clientData = "windows365client";

const getClient = (data) => {
  const { W365server, cloner_key, user, orgID } = data;
  const id = getW365ClientId;

  return async (dispatch) => {
    dispatch(worker.start(id));
    try {
      // console.log("trying to get client", user, orgID);
      const url = `${W365server}mailapi/organization/${orgID}/clients`;
      const response = await axios.get(url, {
        headers: { cloner_key },
      });
      const data = response.data.data;

      for (const client of data) {
        if (client.cloner_user === user) {
          // console.log("data", data);
          let url2 = `${W365server}mailapi/organization/${orgID}/client/${client.client_mail}/space`;
          const response2 = await axios.get(url2, {
            headers: { cloner_key },
          });
          const data2 = response2.data.data;
          // console.log("response2", response2.data.data);

          dispatch(
            UISliceActions.setValue({
              id: bkpMailCountId + user,
              value: data2.count_mails,
            })
          );
          dispatch(
            UISliceActions.setValue({
              id: bkpDriveSizeId + user,
              value: data2.drive,
            })
          );
          dispatch(warehouse.load({ id: w365clientData, data: client }));
          break;
        }
      }
      // const url = `${W365server}mailapi/client`;
      // const response = await axios.get(url, {
      //   headers: { cloner_key },
      //   params: { cloner_user },
      // });
      // const data = response.data.data;
      // console.log("data", data);
      // dispatch(warehouse.load({ id: w365clientData, data }));
      dispatch(worker.finish({ id }));
    } catch (error) {
      dispatch(worker.finish({ id, error }));
    }
  };
};

export default getClient;
