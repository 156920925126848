const HeadersData = [
  {
    UIText: {
      "es-CL": { label: "Usuario" },
      "en-US": { label: "User" },
    },
    filterKey:'mail',
    xs: 2.4,
  },
  {
    UIText: {
      "es-CL": { label: "Correo W365" },
      "en-US": { label: "W365 email" },
    },
    filterKey:'w365mail',
    xs: 2.4,
  },
  {
    UIText: {
      "es-CL": { label: "Dominio" },
      "en-US": { label: "Domain" },
    },
    filterKey:'w365domain',
    xs: 2.4,
  },
  {
    UIText: {
      "es-CL": { label: "Respaldar correos" },
      "en-US": { label: "Backup Emails" },
    },
    // justify:'center',
    xs: 2.4,
  },
  {
    UIText: {
      "es-CL": { label: "Respaldar Onedrive" },
      "en-US": { label: "Backup Onedrive" },
    },
    // justify:'center',
    xs: true,
  },
];

export default HeadersData