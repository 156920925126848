import { Grid, Typography } from "@mui/material";
import CheckButton from "../../../../UI2/Specialized/Check";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { humanBytes } from "../../../../../utility/humanizers";
import { useEffect } from "react";
import { UISliceActions } from "../../../../../store/slices/UI2";

export const bkpOneArrayId = "bkpOne";
export const bkpDriveSizeId = "bkpDriveSize-";

const BackupOnedrive = ({ login, fontSize }) => {
  const { dispatch, UI } = useUIBoilerplate();
  const driveSize = UI[bkpDriveSizeId + login] ?? 0;
  // const { mails } = UIText[settings.locale];

  useEffect(() => {
    return () => {
      dispatch(UISliceActions.clear(bkpDriveSizeId + login));
    };
  },[dispatch, login]);

  return (
    <Grid
      container
      item
      {...{ xs: 12, alignItems: "center" }}
    >
      <CheckButton
        {...{
          id: login,
          arrayId: bkpOneArrayId,
          xs: "auto",
          fontSize: 1,
          clearOnExit: true,
        }}
      />
      <Grid container item {...{ xs: "auto", justifyContent: "center" }}>
        <Typography sx={{ fontSize: "0.8rem" }}>
          {driveSize?`(${humanBytes(driveSize)})`:null}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BackupOnedrive;
