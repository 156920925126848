import { Dialog } from "@mui/material";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { useEffect } from "react";
import { workersSliceActions as workers } from "../../store/slices/workers";
import User, { userUI } from "./Components/User";
import { postUserId } from "../../store/actions2/UserForm/postUser";
import BasicDialogTitle from "../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../UI2/BasicDialog/BasicDialogContent";
import Buttons from "./Components/Buttons/Buttons";
import W365, { w365Switch } from "./Components/W365/W365";
import W365Userform, {
  bkpDriveSwitchId,
  bkpMailSwitchId,
  m365MailId,
  m365setup,
  mailLockId,
} from "./Components/W365/W365Userform";
import { getW365OrgId } from "../../store/actions2/Windows365/getOrganization";
import {
  getW365ClientId,
  w365clientData,
} from "../../store/actions2/Windows365/getClient";
import {
  getPlatformAndServicesId,
  platformServices,
} from "../../store/actions2/Windows365/getPlatformsAndServices";
import { warehouseSliceActions } from "../../store/slices/warehouse";
import { bkpDriveSizeId } from "../UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";
import { bkpMailCountId } from "../UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { m365validated } from "./Components/Buttons/Save";
import { postClientId } from "../../store/actions2/Windows365/postClient";
import { validateMailId } from "../../store/actions2/Windows365/validateW365Mails";
import { suggestMailId } from "../../store/actions2/Windows365/suggestW365Mails";
import { getW365ClientsSizeId } from "../../store/actions2/Windows365/getClientsSize";

const UIText = {
  "es-CL": {
    header: "Integraciones Cloud",
  },
  "en-US": {
    header: "Cloud Integrations",
  },
};

const UserCloudIntegrations = (props) => {
  const { isNew } = props;
  const { dispatch, settings, UI } = useUIBoilerplate();
  const locale = settings.locale;
  const { payload } = UI.modal;
  const { users = [] } = payload;

  // useEffect(() => {
  //   dispatch(
  //     loadGroupsTree({
  //       ...auth,
  //       startGroup: parseInt(group),
  //       mode: "modal",
  //     })
  //   );
  // }, [dispatch, auth, group]);

  useEffect(() => {
    if (users.length === 1) {
      const userData = users[0];
      if (userData.login) {
        dispatch(UIactions.setField({ id: userUI, value: userData.login }));
      }
    }
  }, [dispatch, users]);

  useEffect(() => {
    return () => {
      dispatch(workers.clear(postUserId));
      dispatch(UIactions.clear(userUI));
      dispatch(UIactions.clear(bkpMailSwitchId));
      dispatch(UIactions.clear(bkpDriveSwitchId));
      dispatch(UIactions.clear(w365Switch));
      dispatch(UIactions.clear(m365MailId));
      dispatch(UIactions.clear(mailLockId));
      dispatch(UIactions.clear(bkpDriveSizeId + users[0].login));
      dispatch(UIactions.clear(bkpMailCountId + users[0].login));
      dispatch(UIactions.clear(m365MailId + "-" + users[0].login));
      dispatch(UIactions.clear(m365validated));
      dispatch(workers.clear(m365setup));
      dispatch(workers.clear(getW365OrgId));
      dispatch(workers.clear(getW365ClientId));
      dispatch(workers.clear(getPlatformAndServicesId));
      dispatch(workers.clear(postClientId));
      dispatch(workers.clear(validateMailId));
      dispatch(workers.clear(suggestMailId));
      dispatch(workers.clear(getW365ClientsSizeId))
      dispatch(warehouseSliceActions.unload(w365clientData));
      dispatch(warehouseSliceActions.unload(platformServices));
    };
  }, [dispatch, users]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  let header = UIText[locale].header;

  return (
    <Dialog {...{ open: true, onClose }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        {/* <Grid container item {...{ rowGap: 2, paddingTop:2, sx: { width: "100%" } }}> */}
        <User {...props} />
        <W365 {...props} />
        
        <W365Userform {...props} />
        {/* <GoogleCloud /> */}
        {/* </Grid> */}
      </BasicDialogContent>
      <Buttons {...{ isNew, users }} />
    </Dialog>
  );
};

export default UserCloudIntegrations;
