import { useMemo } from "react";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../hooks2/useWorker";
import { w365orgsData } from "../../../../store/actions2/Windows365/getOrganizations";
import {
  suggestMailId,
  suggestW365Mails,
} from "../../../../store/actions2/Windows365/suggestW365Mails";
import Button from "../../../UI2/Button";
import { mailLocksId } from "../UsersList/UserRow/W365mail";
import { arrayId } from "../UsersList/UsersList";
import { postClientsId } from "../../../../store/actions2/Windows365/postClients";
import { validateMailId } from "../../../../store/actions2/Windows365/validateW365Mails";
import { deleteClientsId } from "../../../../store/actions2/Windows365/deleteClients";

const UITextSuggest = {
  "es-CL": {
    label: "Sugerir correo",
    fixedTooltip:
      "Sugiere un correo para Windows 365 valido, utilizando el usuario como referencia",
  },
  "en-US": {
    label: "Suggest mail",
    fixedTooltip:
      "Suggest a valid Windows 365 email, using the user as reference",
  },
};

export const newUsersUI = "newUsers";

const SuggestMail = ({ users }) => {
  const { auth, dispatch, settings, UI, warehouse } = useUIBoilerplate();
  const { working } = useWorker(suggestMailId);
  const { working: w1 } = useWorker(postClientsId);
  const { working: w2 } = useWorker(validateMailId);
  const { working: w3 } = useWorker(deleteClientsId);
  const { cloner_key, W365server } = auth;
  const { locale } = settings;
  const selectedUsers = useMemo(()=>UI[arrayId] ?? [],[UI]);
  const lockedMails = useMemo(()=>UI[mailLocksId] ?? [],[UI]);

  // console.log("SELECTED USERS", selectedUsers);
  // console.log("USERS", users);
  let payload = useMemo(() => {
    let tryouts = [];

    for (const user of selectedUsers) {
      let skipUser = false;
      for (const locked of lockedMails) {
        if (locked === user) {
          skipUser = true;
          break;
        }
      }
      if (skipUser) continue;

      for (const user2 of users) {
        if (user2.login === user) {
          tryouts.push({
            login: user2.login,
            org: warehouse[w365orgsData][user2.group],
          });
        }
      }
    }
    return tryouts;
  }, [lockedMails, selectedUsers, users, warehouse]);

  const handleSuggest = (e) => {
    dispatch(
      suggestW365Mails({ tryouts: payload, cloner_key, W365server, locale })
    );
  };

  return (
    <Button
      {...{
        disabled: selectedUsers.length === 0 || payload.length === 0 || w1 || w2 || w3,
        UIText: UITextSuggest,
        onClick: handleSuggest,
        working,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
      }}
    />
  );
};

export default SuggestMail;
