import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../../../store/slices/UI2";
import Button from "../../../UI2/Button";
import { arrayId } from "../UsersList/UsersList";
import { suggestMailId } from "../../../../store/actions2/Windows365/suggestW365Mails";
import { deleteClientsId } from "../../../../store/actions2/Windows365/deleteClients";
import { validateMailId } from "../../../../store/actions2/Windows365/validateW365Mails";
import { postClientsId } from "../../../../store/actions2/Windows365/postClients";
import { useWorker } from "../../../../hooks2/useWorker";
import { getW365ClientsId } from "../../../../store/actions2/Windows365/getClients";
import { getPlatformAndServicesId } from "../../../../store/actions2/Windows365/getPlatformsAndServices";

const UITextUpload = {
  "es-CL": {
    label: "Seleccionar vacíos",
    fixedTooltip:
      "Selecciona todos los usuarios sin correo windows 365 asignado",
  },
  "en-US": {
    label: "Select empty",
    fixedTooltip: "Select all users without a windows 365 email assigned",
  },
};

export const newUsersUI = "newUsers";

const SelectEmpty = ({ users }) => {
  const { dispatch, UI } = useUIBoilerplate();
  const { working: w1 } = useWorker(postClientsId);
  const { working: w2 } = useWorker(validateMailId);
  const { working: w3 } = useWorker(deleteClientsId);
  const { working: w4 } = useWorker(suggestMailId);
  const { done: d2 } = useWorker(getW365ClientsId);
  const { done: d3 } = useWorker(getPlatformAndServicesId);

  const handleSelect = (e) => {
    let justUsers = users.map((user) => user.login);

    // console.log("handleSelect", userList, justUsers);

    let newSelection = []

    for (const user of justUsers) {
      let mailField = UI["365mail-" + user] ?? {};
      // console.log('mailField',user, mailField.value)
      if (!mailField.value) {
        newSelection.push(user)
      }
    }

    dispatch(UISliceActions.setValue({ id: arrayId, value: newSelection }));
  };

  // let working = false;

  return (
    <Button
      {...{
        disabled: w1 || w2 || w3 || w4 || !d2 || !d3,
        UIText: UITextUpload,
        onClick: handleSelect,
        // working,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
      }}
    />
  );
};

export default SelectEmpty;
