import { Cloud } from "@mui/icons-material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import ActionButton from "../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import useURLQuery from "../../../../hooks2/useURLQuery";
import { usersDataId } from "../../../../store/actions2/Users/getAllUsers";
import { useEffect } from "react";
import { getOrganization, getW365OrgId, w365orgData } from "../../../../store/actions2/Windows365/getOrganization";
import { workersSliceActions as workers } from "../../../../store/slices/workers";
import { warehouseSliceActions as warehouseActions } from "../../../../store/slices/warehouse";
import { useWorker } from "../../../../hooks2/useWorker";

const UIText = {
  "es-CL": { label: "Cloud" },
  "en-US": { label: "Cloud" },
};

let userCloudDomainId = "userCloudDomain";

const CloudIntegrations = () => {
  const { auth, dispatch, UI, warehouse } = useUIBoilerplate();
  const { group } = useURLQuery();
  const { done } = useWorker(getW365OrgId);
  const usersData = warehouse[usersDataId]??[]
  const selected = UI.selected ?? [];

  const w365org = warehouse[w365orgData] ?? {};
  const parent = w365org.parent ?? {};
  const domain = parent.mail_account ? parent.mail_account.split("@")[1] : null;

  // console.log("w365org", w365org);

  useEffect(() => {
    if (!done) {
      console.log("getOrganization5 dispatched");
      dispatch(
        getOrganization({
          ...auth,
          groupId: group,
        })
      );
    }
  }, [auth, dispatch, group,done]);

  useEffect(() => {
    return () => {
      dispatch(workers.clear(getW365OrgId));
      dispatch(warehouseActions.unload(w365orgData));
    };
  }, [dispatch]);

  let selectedUsers = []
  for (const user of selected){
    const userData = usersData.find(x=> x.login === user)
    selectedUsers.push(userData)
  }


  const onClick = () => {
    dispatch(
      UIactions.openModal({
        type: "multi-cloud-integrations",
        payload: { group, users: selectedUsers },
      })
    );
  };


  return (
    <ActionButton
      {...{ disabled: !selected.length || !domain, onClick, UIText, icon: <Cloud /> }}
    />
  );
};

export default CloudIntegrations;
