import Button from "../../../UI2/Button";
import { suggestMailId, suggestW365Mails } from "../../../../store/actions2/Windows365/suggestW365Mails";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../hooks2/useWorker";
import { validateMailId } from "../../../../store/actions2/Windows365/validateW365Mails";

const UITextSuggest = {
  "es-CL": {
    label: "Sugerir correo",
    fixedTooltip:
      "Sugiere un correo para Microsoft 365 valido, utilizando el usuario como referencia",
  },
  "en-US": {
    label: "Suggest mail",
    fixedTooltip:
      "Suggest a valid Microsoft 365 email, using the user as reference",
  },
};

export const newUsersUI = "newUsers";

const SuggestMail = ({ login, org }) => {
  const { auth, dispatch, settings } = useUIBoilerplate();
  const { working } = useWorker(suggestMailId);
  const { working: w2 } = useWorker(validateMailId);
  const { cloner_key, W365server } = auth;
  const { locale } = settings;

  let payload = [
    {
      login,
      org,
    },
  ];

  const handleSuggest = (e) => {
    dispatch(
      suggestW365Mails({ tryouts: payload, cloner_key, W365server, locale })
    );
  };

  return (
    <Button
      {...{
        disabled: w2,
        UIText: UITextSuggest,
        onClick: handleSuggest,
        working,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
        xs: 6,
      }}
    />
  );
};

export default SuggestMail;
