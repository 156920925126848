import { Grid } from "@mui/material";
import GroupsTraveler from "../../../../GroupsTraveler/GroupsTraveler";
import DeleteGroup from "./Buttons/DeleteGroup";
import EditGroup from "./Buttons/EditGroup";
import Locale from "../Actions/Locale";
import HelpMode from "../Actions/HelpMode";
import UserOptions from "../Actions/UserOptions";
import CloudBackup from "./Buttons/CloudBackup";
import VerticalDivider from "../../../../UI2/VerticalDivider";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { useLocation } from "react-router-dom";

const UsersTop = ({ height }) => {
  const { auth, UI } = useUIBoilerplate();
  const location = useLocation() ?? {};
  let isUsers = location.pathname === "/dashboard/users";

  const { currentGroup } = UI;
  const barHeight = (height * 8) / 100;

  return (
    <Grid
      container
      item
      {...{
        bgcolor: "white",
        height: barHeight,
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 3,
        paddingRight: 3,
        xs: 12,
        borderBottom: "1px lightgrey solid",
      }}
    >
      <Grid container item {...{ columnGap: 2, xs: "auto" }}>
        <GroupsTraveler {...{ xs: "auto" }} />
        <VerticalDivider {...{ xs: "auto" }} />
        <Grid container item {...{ columnGap: 1, xs: "auto" }}>
          {auth.admin === currentGroup ? null : <EditGroup />}
          {/* <EditGroup /> */}
          {isUsers ? <CloudBackup /> : null}
          <DeleteGroup />
        </Grid>
      </Grid>
      <Grid container item {...{ alignItems: "center", xs: "auto" }}>
        <Locale />
        <HelpMode />
        <UserOptions />
      </Grid>
    </Grid>
  );
};

export default UsersTop;
