import { Grid, Typography } from "@mui/material";
import CheckButton from "../../../../UI2/Specialized/Check";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../../../../store/slices/UI2";
import { useEffect } from "react";

const UIText = {
  "en-US": {
    mails: "mails",
  },
  "es-CL": {
    mails: "correos",
  },
};

export const bkpMailArrayId = "bkpMail";
export const bkpMailCountId = "bkpMailCount-";

const BackupMail = ({ login, fontSize }) => {
  const { dispatch,UI, settings } = useUIBoilerplate();
  const mailsCount = UI[bkpMailCountId + login] ?? 0;
  const { mails } = UIText[settings.locale];

  useEffect(() => {
    return () => {
      dispatch(UISliceActions.clear(bkpMailCountId + login));
    };
  },[dispatch, login]);

  return (
    <Grid
      container
      item
      {...{ xs: 12, alignItems: "center" }}
    >
      <CheckButton
        {...{
          id: login,
          arrayId: bkpMailArrayId,
          xs: "auto",
          fontSize: 1,
          clearOnExit: true,
        }}
      />
      <Grid container item {...{ xs: "auto", justifyContent: "center" }}>
        <Typography sx={{ fontSize: "0.8rem" }}>
          {mailsCount ?`(${mailsCount} ${mails})`:null}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BackupMail;
