import IconButton from "../../../../../UI2/IconButton2";
import { UISliceActions as UIactions } from "../../../../../../store/slices/UI2";
import { useDispatch } from "react-redux";
import { Backup } from "@mui/icons-material";
import { workersSliceActions as workers } from "../../../../../../store/slices/workers";
import { getW365OrgId } from "../../../../../../store/actions2/Windows365/getOrganization";

const UIText = {
  "es-CL": { fixedTooltip: "Windows365" },
  "en-US": { fixedTooltip: "Windows365" },
};

const CloudBackup = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    // dispatch(workers.clear(getW365OrgId));
    dispatch(UIactions.openModal({ type: "cloud-backup" }));
  };

  return (
    <IconButton
      {...{
        UIText,
        icon: <Backup color="primary" />,
        onClick: handleClick,
      }}
    />
  );
};

export default CloudBackup;
