import { useMemo } from "react";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../hooks2/useWorker";
import { deleteClientsId } from "../../../../store/actions2/Windows365/deleteClients";
import {
  suggestMailId,
} from "../../../../store/actions2/Windows365/suggestW365Mails";
import { UISliceActions } from "../../../../store/slices/UI2";
import Button from "../../../UI2/Button";
import { arrayId } from "../UsersList/UsersList";
import { mailLocksId } from "../UsersList/UserRow/W365mail";
import { validateMailId } from "../../../../store/actions2/Windows365/validateW365Mails";
import { postClientsId } from "../../../../store/actions2/Windows365/postClients";

const UITextDelete = {
  "es-CL": {
    label: "Resetear Clientes",
    fixedTooltip:
      "Desvincula el usuario cloner del correo de Windows 365 asingnado, deteniendo el respaldo",
  },
  "en-US": {
    label: "Reset Clients",
    fixedTooltip:
      "Unlink the cloner user from the assigned Windows 365 email, stopping the backup",
  },
};

export const newUsersUI = "newUsers";

const ResetClient = ({ users }) => {
  const { dispatch, UI } = useUIBoilerplate();
  const { working } = useWorker(deleteClientsId);
  const { working: w1 } = useWorker(postClientsId);
  const { working: w2 } = useWorker(suggestMailId);
  const { working: w3 } = useWorker(validateMailId);
  const selectedUsers = useMemo(()=> UI[arrayId] ?? [], [UI]);
  const lockedMails = useMemo(()=>UI[mailLocksId] ?? [],[UI]);

  // console.log("SELECTED USERS", selectedUsers);
  // console.log("USERS", users);
  
  let trimmedSelected = useMemo(() => {
    let tryouts = [];

    for (const user of selectedUsers) {
      for (const locked of lockedMails) {
        if (locked === user) {
          tryouts.push(user);
          break;
        }
      }
    }
    return tryouts;
  }, [lockedMails, selectedUsers]);

  const handleDelete = (e) => {
    dispatch(
      UISliceActions.openModal2({
        type: "delete-w365-clients-confirmation",
        payload: { selectedUsers: trimmedSelected },
      })
    );
  };

  return (
    <Button
      {...{
        disabled: trimmedSelected.length === 0 || w1 || w2 || w3,
        UIText: UITextDelete,
        onClick: handleDelete,
        working,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
        color: "error",
      }}
    />
  );
};

export default ResetClient;
