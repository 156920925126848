import Button from "../../../UI2/Button";
import { suggestMailId } from "../../../../store/actions2/Windows365/suggestW365Mails";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../hooks2/useWorker";
import { m365MailId } from "./W365Userform";
import { validateMailId, validateW365Mails } from "../../../../store/actions2/Windows365/validateW365Mails";

const UITextVerify = {
  "es-CL": {
    label: "Verificar correo",
    fixedTooltip: "Verifica que el correo para Windows 365 es valido",
  },
  "en-US": {
    label: "Verify mail",
    fixedTooltip: "Verify that the email for Windows 365 is valid",
  },
};

export const newUsersUI = "newUsers";

const VerifyMail = ({ login,org }) => {
  const { auth, dispatch, settings, UI } = useUIBoilerplate();
  const { working } = useWorker(validateMailId);
  const { working:w2 } = useWorker(suggestMailId);
  const { cloner_key, W365server } = auth;
  const { locale } = settings;
  const mailField = UI[m365MailId + "-" + login] ?? {};
  const mail = mailField.value;

  let payload = [{
    mail,
    login,
    org,
  }]

  const handleVerify = (e) => {
    dispatch(
      validateW365Mails({ tryouts: payload, cloner_key, W365server, locale })
    );
  };

  return (
    <Button
      {...{
        disabled: w2,
        UIText: UITextVerify,
        onClick: handleVerify,
        working,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
        xs:6,
      }}
    />
  );
};

export default VerifyMail;
