import axios from "axios";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as worker } from "../../slices/workers";

export const getW365ClientsId = "getW365Clients";
export const w365clientsData = "windows365clients";

const getClients = (data) => {
  const { W365server, cloner_key, orgIDs } = data;
  const id = getW365ClientsId;

  return async (dispatch) => {
    dispatch(worker.start(id));
    let clients = [];
    for (const orgId of orgIDs) {
      try {
        const url = `${W365server}mailapi/organization/${orgId}/clients`;
        const response = await axios.get(url, {
          headers: { cloner_key },
        });
        const data = response.data.data;
        // console.log("data", data);
        clients.push(...data);

      } catch (error) {
        console.error(error);
        // dispatch(worker.finish({ id, error }));
      }
    }

    dispatch(warehouse.load({ id: w365clientsData, data: clients }));
    dispatch(worker.finish({ id }));
  };
};

export default getClients;
