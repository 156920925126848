import { Avatar, Grid, Typography } from "@mui/material";
import Switch from "../../../UI2/Switch";
import { useWorker } from "../../../../hooks2/useWorker";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useEffect, useMemo } from "react";
import {
  getOrganization,
  getW365OrgId,
  w365orgData,
} from "../../../../store/actions2/Windows365/getOrganization";
import getClient, {
  getW365ClientId,
  w365clientData,
} from "../../../../store/actions2/Windows365/getClient";
import { getPlatformAndServicesId, getPlatformsAndServices } from "../../../../store/actions2/Windows365/getPlatformsAndServices";

const UIText = {
  "es-CL": { label: "Microsoft365", noDomain: "Grupo sin dominio" },
  "en-US": { label: "Microsoft365", noDomain: "Group lacks domain" },
};

export const w365Switch = "w365Switch";

const W365 = () => {
  const { auth, dispatch, settings, warehouse, UI } = useUIBoilerplate();
  const { W365server, cloner_key } = auth;
  const { done: done1, working } = useWorker(getW365OrgId);
  const { done, working: w2 } = useWorker(getW365ClientId);
  const { done: done3 } = useWorker(getPlatformAndServicesId);
  const locale = settings.locale;
  const { label, noDomain } = UIText[locale];
  const { payload } = UI.modal;
  const { users = [] } = payload;

  const w365org = useMemo(() => warehouse[w365orgData] ?? {}, [warehouse]);

  const own = w365org.own ?? {};
  const parent = w365org.parent ?? {};

  // const isRoot = parseInt(group) === auth.group;
  const availableCert = own.client_id ? own : parent;
  const domain = availableCert.mail_account
    ? availableCert.mail_account.split("@")[1]
    : null;

  const client = warehouse[w365clientData] ?? {};
  // console.log("client", client);
  
  useEffect(() => {
    if (!done3) {
      dispatch(getPlatformsAndServices({ W365server, cloner_key, locale }));
    }
  }, [dispatch, done3, W365server, cloner_key, locale]);

  useEffect(() => {
    // console.log("getOrganization4 dispatched");
    if (!done1) {
      dispatch(
        getOrganization({
          ...auth,
        })
      );
    }
  }, [dispatch, auth, done1]);

  // console.log("domain", domain);
  // console.log("ready to go?", done, done1);

  useEffect(() => {
    if (!done && done1) {
      // console.log("getW365ClientId dispatched");
      dispatch(
        getClient({
          ...auth,
          user: users[0].login,
          orgID: w365org.own.client_id ? w365org.own.id : w365org.parent.id,
        })
      );
    }
  }, [dispatch, auth, done, done1, users, w365org]);

  // console.log("users", users);

  useEffect(() => {
    if (client.client_mail) {
      dispatch(UIactions.setValue({ id: w365Switch, value: true }));
    } else {
      dispatch(UIactions.setValue({ id: w365Switch, value: false }));
    }
  }, [dispatch, client.client_mail]);

  return (
    <Grid
      container
      item
      // xs={12}
      alignItems="center"
      columnGap={2}
      sx={{ paddingBottom: 6, height: "5vh", width: "100%" }}
    >
      <Grid container item xs={1} alignItems="center" justifyContent="center">
        <Switch
          // disableDefault
          {...{ id: w365Switch, disabled: !domain }}
        />
      </Grid>
      <Avatar
        alt="Windows365-icon"
        src="https://upload.wikimedia.org/wikipedia/commons/0/0e/Microsoft_365_%282022%29.svg"
        variant="rounded"
        sx={{ height: 30, width: 27.2 }}
      />
      <Typography fontSize={16}>
        {label}
        {working || w2
          ? null
          : ` ( ${domain ? "@" : ""}${domain ?? noDomain} )`}
      </Typography>
    </Grid>
  );
};

export default W365;
