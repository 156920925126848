import axios from "axios";
import { workersSliceActions as worker } from "../../slices/workers";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { enqueueSnackbar } from "notistack";

export const getW365OrgsId = "windows365organizations";
export const w365orgsData = "windows365organizations";

const UIError = {
  "es-CL": {
    error: "Error al obtener organizaciones",
  },
  "en-US": {
    error: "Error getting organizations",
  },
};

export const getOrganizations = (data) => {
  const id = getW365OrgsId;
  const { W365server, cloner_key, groups, locale } = data;

  // console.log("getOrganizations", data);

  return async (dispatch) => {
    dispatch(worker.start(id));
    // dispatch(warehouse.unload(w365orgsData));
    // const group_id = groupId ?? group;

    let lastError = null;
    for (let group_id of groups) {
      try {
        const url = `${W365server}mailapi/organization`;
        const response = await axios.get(url, {
          params: { group_id },
          headers: { cloner_key },
        });

        const org = response.data.data;
        // console.log("ORG", org);

        const parent = org.parent ?? {};
        const domain = parent.mail_account
          ? parent.mail_account.split("@")[1]
          : null;

        dispatch(
          warehouse.add({
            id: w365orgsData,
            key: group_id,
            data: {
              id: org.own.client_id?org.own.id:org.parent.id,
              domain
            },
          })
        );
        // dispatch(worker.finish({ id }));
      } catch (e) {
        console.error(e);
        lastError = e;
        // ACA REVISAR SI LAREQUEST FALLO MAL O SI ES QUE NO EXISTE LA ORGANIZACION
        // dispatch(worker.finish({ id, error: e.message }));
      }
    }

    if (lastError) {
      enqueueSnackbar(UIError[locale].error, { variant: "error" });
      dispatch(worker.finish({ id, error: lastError.message }));
    } else {
      dispatch(worker.finish({ id }));
    }

    // try {
    //   const url = `${W365server}mailapi/organization`;
    //   const response = await axios.get(url, {
    //     params: { group_id },
    //     headers: { cloner_key },
    //   });

    //   const org = response.data.data;
    //   console.log('ORG',org)

    //   dispatch(warehouse.load({ id: w365orgData, data: org }));
    //   dispatch(worker.finish({ id }));
    // } catch (e) {
    //   console.error(e);
    //   // ACA REVISAR SI LAREQUEST FALLO MAL O SI ES QUE NO EXISTE LA ORGANIZACION
    //   dispatch(worker.finish({ id, error: e.message }));
    // }
  };
};
