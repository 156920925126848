import axios from "axios";
import { workersSliceActions as worker } from "../../slices/workers";
import { UISliceActions } from "../../slices/UI2";
import { bkpMailCountId } from "../../../components/UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { bkpDriveSizeId } from "../../../components/UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";

export const getW365ClientsSizeId = "getW365ClientsSize";

const getClientsSize = (data) => {
  const { W365server, cloner_key, orgID, user, userMail } = data;
  const id = getW365ClientsSizeId;

  return async (dispatch) => {
    dispatch(worker.start(id));

    try {
      let url = `${W365server}mailapi/organization/${orgID}/client/${userMail}/space`;
      const response = await axios.get(url, { headers: { cloner_key } });
      const data2 = response.data.data;

      dispatch(
        UISliceActions.setValue({
          id: bkpMailCountId + user,
          value: data2.count_mails,
        })
      );
      dispatch(
        UISliceActions.setValue({
          id: bkpDriveSizeId + user,
          value: data2.drive,
        })
      );

      // dispatch(warehouse.load({ id: w365clientData, data }));
      // dispatch(worker.finish({ id }));
    } catch (error) {
      console.error(error);
      // dispatch(worker.finish({ id, error }));
    }

    dispatch(worker.finish({ id }));
  };
};

export default getClientsSize;
