import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as worker } from "../../slices/workers";
import { UISliceActions } from "../../slices/UI2";
import { getW365ClientsId } from "./getClients";
import { bkpOneArrayId } from "../../../components/UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";
import { bkpMailArrayId } from "../../../components/UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { arrayId } from "../../../components/UsersCloudIntegration/Components/UsersList/UsersList";
import { mailLocksId } from "../../../components/UsersCloudIntegration/Components/UsersList/UserRow/W365mail";

const UIText = {
  "es-CL": {
    success: "Remoción del usuario de Windows365 exitosa",
    skipped: "Se intentó activar un usuario sin activar ningun servicio: ",
    error: "Error al activar usuario de Windows365: ",
    fullSuccess:
      "Todos los usuarios fueron removidos exitosamente",
    partialSuccess:
      "Solo algunos usuarios fueron activados o modificados exitosamente, revisar detalles",
    fullFail: "Todos los usuarios fallaron al activar o modificar",
    success2: "Exito",
    ignored: "Ignorado",
    fail: "Fallo",
  },
  "en-US": {
    success: "Windows365 user removal successful",
    skipped:
      "An attempt was made to activate a user without activating any services: ",
    error: "Error while activating Windows365 user: ",
    fullSuccess: "All users were successfully removed",
    partialSuccess:
      "Only some users were successfully activated or modified, check details",
    fullFail: "All users failed to activate or modify",
    success2: "Success",
    ignored: "Ignored",
    fail: "Failed",
  },
};

export const deleteClientsId = "delete365client";

const deleteClients = (data) => {
  const { W365server, cloner_key, payloads, locale } = data;
  const id = deleteClientsId;
  const {
    success,
    error,
    fullSuccess,
    partialSuccess,
    fullFail,
    success2,
    fail,
  } = UIText[locale];
  return async (dispatch) => {
    dispatch(worker.start(id));
    let results = [];
    for (let user of payloads) {
      console.log("postClient payload", user);
      // const bodyData = JSON.stringify(payload);
      // console.log("postClient bodyData", bodyData);
      try {
        const url = `${W365server}mailapi/client/${user}`;
        await axios.delete(url, {
          // maybe needs body?
          headers: { cloner_key },
        });

        results.push({ user, status: success2 });
        enqueueSnackbar(success, { variant: "success" });
      } catch (e) {
        console.error(e);
        results.push({ user, status: fail });
        enqueueSnackbar(error + e.message, { variant: "error" });
      }
    }

    console.log("results", results);
    let fullOk = true;
    let fullFail2 = true;
    for (let result of results) {
      if (result.status === success2) {
        fullFail2 = false;
      } else {
        fullOk = false;
      }
    }

    console.log("results", fullOk, fullFail2);

    if (fullOk) {
      enqueueSnackbar(fullSuccess, { variant: "success" });
    } else if (fullFail2) {
      enqueueSnackbar(fullFail, { variant: "error" });
    } else {
      enqueueSnackbar(partialSuccess, { variant: "warning" });
    }
    dispatch(worker.finish({ id }));
    dispatch(worker.clear(getW365ClientsId));

    for (let result of results) {
      if (result.status === success2) {
        dispatch( UISliceActions.clear("365mail-"+result.user));
        dispatch( UISliceActions.remove({id: bkpOneArrayId, key: result.user}));
        dispatch( UISliceActions.remove({id: bkpMailArrayId, key: result.user}));
        dispatch( UISliceActions.remove({id: arrayId, key: result.user}));
        dispatch( UISliceActions.remove({id: mailLocksId, key: result.user}));
      }
    }

    // if (!fullFail2) {
    //   console.log("clearing clients");
    //   dispatch(
    //     UISliceActions.openModal2({
    //       type: "download-w365-post-results",
    //       payload: { results },
    //     })
    //   );
    // }
  };
};

export default deleteClients;
