import {
  AccountTree,
  Assessment,
  Cloud,
  Delete,
  Edit,
  History,
  InsertDriveFile,
  Laptop,
  // LocalShipping,
} from "@mui/icons-material";
import MoreActions from "../../../../UI2/BasicLayout/Components/MoreActions/MoreActions";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import useURLQuery from "../../../../../hooks2/useURLQuery";
import { defaultPreFilter } from "../../../../UI2/genericIDs";
import { w365orgData } from "../../../../../store/actions2/Windows365/getOrganization";
// import { recoveriesSliceActions as recoveries} from "../../../../../store/slices/recoveries";
// import useRecoveries from "../../../../../hooks2/useRecoveries";
// import createUsersRequest from "../../../../../store/actions2/Recoveries/createUsersRequest";

const Actions = (props) => {
  const { login, group, machines, tunnels = [] } = props;
  const { auth, dispatch, warehouse/*,settings*/} = useUIBoilerplate();
  const { group: uGroup } = useURLQuery();
  // const { initialized } = useRecoveries()??{}
  // const locale = settings.locale;
  const w365org = warehouse[w365orgData] ?? {};
  const parent = w365org.parent ?? {};
  const domain = parent.mail_account ? parent.mail_account.split("@")[1] : null;

  const data = {
    id: login,
    buttonsData: [
      {
        icon: <Edit sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Editar usuario" },
          "en-US": { label: "Edit user" },
        },
        disabled: auth.user === login,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "edit-user",
              payload: { group, users: [props] },
            })
          );
        },
      },
      {
        icon: <Cloud sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Integraciones Cloud" },
          "en-US": { label: "Cloud Integrations" },
        },
        disabled: auth.user === login || !domain,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "edit-user-cloud",
              payload: { group, users: [props] },
            })
          );
        },
      },
      { divider: true },
      {
        icon: <History sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Historial" },
          "en-US": { label: "History" },
        },
        disabled: !machines,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "selection-history",
              payload: { user: login },
            })
          );
        },
      },
      {
        icon: <AccountTree sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Selección remota" },
          "en-US": { label: "Remote selection" },
        },
        disabled: !tunnels.length,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "remote-selection",
              payload: { user: login },
            })
          );
        },
      },
      { divider: true },
      {
        icon: <Assessment sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Reportes" },
          "en-US": { label: "Reports" },
        },
        disabled: !machines,
        navTo: `/dashboard/reports?group=${uGroup}`,
        onClick: () => {
          if (login) {
            dispatch(
              UIactions.setValue({ id: defaultPreFilter, value: login })
            );
          }
        },
      },
      {
        icon: <InsertDriveFile sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Archivos" },
          "en-US": { label: "Files" },
        },
        disabled: !machines,
        navTo: `/dashboard/files?user=${encodeURIComponent(login)}`,
      },
      {
        icon: <Laptop sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Dispositivos" },
          "en-US": { label: "Devices" },
        },
        disabled: !machines,
        navTo: `/dashboard/devices?group=${uGroup}`,
        onClick: () => {
          if (login) {
            dispatch(
              UIactions.setValue({ id: defaultPreFilter, value: login })
            );
          }
        },
      },
      // {
      //   icon: <LocalShipping sx={{ color: "grey" }} />,
      //   UIText: {
      //     "es-CL": { label: "Recuperar" },
      //     "en-US": { label: "Recover" },
      //   },
      //   disabled: !machines,
      //   onClick: () => {
      //     if (!initialized) {
      //       dispatch(recoveries.reset());
      //       dispatch(
      //         createUsersRequest({
      //           ...auth,
      //           users: [login],
      //           locale,
      //         })
      //       );
      //     } else {
      //       dispatch(
      //         UIactions.openModal({
      //           type: "new-users-request",
      //           payload: { users: [login] },
      //         })
      //       );
      //     }
      //   },
      // },
      {
        icon: <Delete sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Eliminar" },
          "en-US": { label: "Delete" },
        },
        disabled: auth.user === login,
        // navTo: `/dashboard/devices?group=${uGroup}`,
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "delete-users",
              payload: { users: [login] },
            })
          );
        },
      },
    ],
  };

  return <MoreActions {...data} />;
};

export default Actions;
