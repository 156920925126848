import {
  UISliceActions as UIactions,
  UISliceActions,
} from "../../../../store/slices/UI2";
import Button from "../../../UI2/Button";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../hooks2/useWorker";
import { useEffect } from "react";
import { suggestMailId } from "../../../../store/actions2/Windows365/suggestW365Mails";
import { validateMailId } from "../../../../store/actions2/Windows365/validateW365Mails";
import { w365clientData } from "../../../../store/actions2/Windows365/getClient";
import {
  bkpDriveSwitchId,
  bkpMailSwitchId,
  m365MailId,
} from "../W365/W365Userform";
import { w365Switch } from "../W365/W365";
import postClient, {
  postClientId,
} from "../../../../store/actions2/Windows365/postClient";
import { w365orgData } from "../../../../store/actions2/Windows365/getOrganization";

const UIText = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};

export const m365validated = "m365validated";

const Save = (props) => {
  const { users } = props;
  const { auth, dispatch, UI, settings, warehouse } = useUIBoilerplate();
  const { W365server, cloner_key } = auth;
  const { done, working, error: e1 } = useWorker(postClientId);
  const { working: w2 } = useWorker(suggestMailId);
  const { working: w3 } = useWorker(validateMailId);
  const validated = UI[m365validated] ?? false;
  const clientData = warehouse[w365clientData] ?? {};
  const bkpMail = UI[bkpMailSwitchId] ?? false;
  const bkpDrive = UI[bkpDriveSwitchId] ?? false;
  const isNew = !clientData.client_mail;
  const active = UI[w365Switch] ?? false;
  const w365org = warehouse[w365orgData] ?? {};
  const mailField = UI[m365MailId + "-" + users[0].login] ?? {};
  const mail = mailField.value;

  const own = w365org.own ?? { mail_account: "" };
  const parent = w365org.parent ?? { mail_account: "" };
  const availableCert = own.client_id ? own : parent;
  const domain = availableCert.mail_account
    ? availableCert.mail_account.split("@")[1]
    : null;

  const locale = settings.locale;

  const onClick = () => {
    // console.log("ON CLICK", isNew, active);
    // console.log("services", services);
    if (!isNew && !active) {
      dispatch(
        UISliceActions.openModal2({
          type: "delete-m365-client-confirmation",
          payload: {
            user: clientData.cloner_user,
          },
        })
      );
    } else if (!isNew) {
      const services = [
        {
          id: "1",
          enabled: bkpDrive,
        },
        {
          id: "2",
          enabled: bkpMail,
        },
      ];
      const payload = {
        organization_id: clientData.organization_id,
        client_mail: clientData.client_mail,
        cloner_user: users[0].login,
        services,
      };
      dispatch(
        postClient({
          W365server,
          cloner_key,
          payload,
          locale,
          reload: false,
        })
      );
    } else if (isNew) {
      const payload = {
        organization_id: availableCert.id,
        client_mail: mail + "@" + domain,
        cloner_user: users[0].login,
        services: [
          {
            id: "1",
            enabled: bkpDrive,
          },
          {
            id: "2",
            enabled: bkpMail,
          },
        ],
      };
      dispatch(
        postClient({
          W365server,
          cloner_key,
          payload,
          locale,
        })
      );
    }
  };

  useEffect(() => {
    if (done && !e1) {
      dispatch(UIactions.closeModal());
    }
  }, [dispatch, done, e1]);

  const error = false;

  return (
    <Button
      {...{
        disabled:
          !!error ||
          (isNew && !validated) ||
          w2 ||
          w3 ||
          (isNew && !bkpMail && !bkpDrive),
        UIText,
        onClick,
        working,
      }}
    />
  );
};

export default Save;
