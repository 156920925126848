import axios from "axios";
import { workersSliceActions as worker } from "../../slices/workers";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";

export const getW365OrgId = "windows365organization";
export const w365orgData = "windows365organization";

export const getOrganization = (data) => {
  const id = getW365OrgId;
  const { W365server, cloner_key, groupId, group } = data;

  console.log("trying to get organization");
  return async (dispatch) => {
    dispatch(worker.start(id));
    dispatch(warehouse.unload("o365org"));
    const group_id = groupId ?? group;
    try {
      const url = `${W365server}mailapi/organization`;
      const response = await axios.get(url, {
        params: { group_id },
        headers: { cloner_key },
      });

      const org = response.data.data;
      // console.log("ORG", org);

      dispatch(warehouse.load({ id: w365orgData, data: org }));
      dispatch(worker.finish({ id }));
    } catch (e) {
      if (e.response.status === 404) {
        console.log("Organization not found");
      } else {
        console.error(e);
      }
      // ACA REVISAR SI LAREQUEST FALLO MAL O SI ES QUE NO EXISTE LA ORGANIZACION
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};
