import axios from "axios";
import { workersSliceActions as worker } from "../../slices/workers";
import { enqueueSnackbar } from "notistack";
import { UISliceActions as UIactions, UISliceActions } from "../../slices/UI2";
import { bkpMailCountId } from "../../../components/UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { bkpDriveSizeId } from "../../../components/UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";
import { m365validated } from "../../../components/UserCloudIntegrations/Components/Buttons/Save";

export const suggestMailId = "windows365suggestions";
// export const w365orgsData = "windows365organizations";

const UIError = {
  "es-CL": {
    error: "Error al sugerir correo",
    notFound: "No se encontró una sugerencia para el correo",
  },
  "en-US": {
    error: "Error suggesting mail",
    notFound: "No suggestion found for the mail",
  },
};

export const suggestW365Mails = (data) => {
  const id = suggestMailId;
  const { W365server, cloner_key, tryouts, locale } = data;

  // console.log("sugges mails", tryouts);

  return async (dispatch) => {
    dispatch(worker.start(id));
    // dispatch(warehouse.unload(w365orgsData));
    // const group_id = groupId ?? group;
    // let lastError = null;
    for (let attempt of tryouts) {
      const newMail = attempt.login.split("@")[0] + "@" + attempt.org.domain;
      const url = `${W365server}mailapi/organization/${attempt.org.id}/client/${newMail}`;
      try {
        const response = await axios.get(url, { headers: { cloner_key } });

        // const client = response.data.data;
        // console.log("client", client);

        // console.log("response", response.status);

        let url2 = `${W365server}mailapi/organization/${attempt.org.id}/client/${newMail}/space`;
        const response2 = await axios.get(url2, { headers: { cloner_key } });
        const data = response2.data.data;
        // console.log("response2", response2.data.data);

        dispatch(
          UISliceActions.setValue({
            id: bkpMailCountId + attempt.login,
            value: data.count_mails,
          })
        );
        dispatch(
          UISliceActions.setValue({
            id: bkpDriveSizeId + attempt.login,
            value: data.drive,
          })
        );

        dispatch(
          UIactions.setField({
            id: "365mail-" + attempt.login,
            value: response.data.data.mail.toLowerCase().split("@")[0],
          })
        );
        dispatch(
          UISliceActions.setValue({
            id: m365validated,
            value: true,
          })
        );
      } catch (e) {
        console.log("error", e);
        // lastError = e;
        dispatch(UISliceActions.clear(bkpMailCountId + attempt.login));
        dispatch(UISliceActions.clear(bkpDriveSizeId + attempt.login));
        if (e.response.status === 404) {
          enqueueSnackbar(UIError[locale].notFound + ": " + newMail, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(UIError[locale].error + ": " + newMail, {
            variant: "error",
          });
        }
        // ACA REVISAR SI LAREQUEST FALLO MAL O SI ES QUE NO EXISTE LA ORGANIZACION
        // dispatch(worker.finish({ id, error: e.message }));
      }
    }

    dispatch(worker.finish({ id }));
  };
};
