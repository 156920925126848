import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const UIText = {
  "es-CL": {
    title: "Incripción y modificación de usuarios",
    filename: "ResumenW365.pdf",
    headers: [
      "User",
      "CorreoW365",
      "Respaldar Correos",
      "Respaldar Onedrive",
      "Resultado",
    ],
    yes: "Si",
    nope: "No",
  },
  "en-US": {
    title: "User registration and modification",
    filename: "SummaryW365.pdf",
    headers: ["User", "W365Mail", "Backup Mails", "Backup Onedrive", "Result"],
    yes: "Yes",
    nope: "No",
  },
};

export const createClientsPDF = (data) => {
  return async (dispatch) => {
    const { pdfData, services, locale } = data;
    const { title, filename, headers, yes, nope } = UIText[locale];

    const doc = new jsPDF({
      orientation: "l", //set orientation
      unit: "pt", //set unit for document
      format: "letter", //set document standard
    });
    doc.text(title, 40, 50);
    doc.setFontSize(13);

    let bodyData = [];
    for (let pData of pdfData) {
      let newRow = [pData.cloner_user, pData.client_mail];

      let x = ["",""];
      for (const s of pData.services) {
        console.log("s", s);
        let S = services.find((x) => x.id === s.id);

        if (S.name === "Microsoft Mail") {
          x[0] = s.enabled ? yes : nope
        } else if (S.name === "Microsoft Drive") {
          x[1] = s.enabled ? yes : nope
        }
      }
      newRow = [...newRow, ...x, pData.status];

      bodyData.push(newRow);
    }

    autoTable(doc, {
      startY: 80,
      head: [
        [
          headers[0],
          headers[1],
          headers[2],
          headers[3],
          headers[4],
        ],
      ],
      body: bodyData,
    });
    doc.save(filename);
  };
};
