import { Grid, Typography } from "@mui/material";
import CollapseGrid from "../../../UI2/CollapseGrid";
import TextField from "../../../UI2/SimpleTextField";
import Switch from "../../../UI2/Switch2";
import { w365Switch } from "./W365";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import SuggestMail from "./SuggestMail";
import VerifyMail from "./VerifyMail";
import { w365orgData } from "../../../../store/actions2/Windows365/getOrganization";
import {
  getW365ClientId,
  w365clientData,
} from "../../../../store/actions2/Windows365/getClient";
import { useEffect, useMemo } from "react";
import { useWorker } from "../../../../hooks2/useWorker";
import { UISliceActions } from "../../../../store/slices/UI2";
import {
  getPlatformAndServicesId,
  platformServices,
} from "../../../../store/actions2/Windows365/getPlatformsAndServices";
import { workersSliceActions } from "../../../../store/slices/workers";
import { userUI } from "../User";
import { bkpMailCountId } from "../../../UsersCloudIntegration/Components/UsersList/UserRow/BackupMail";
import { bkpDriveSizeId } from "../../../UsersCloudIntegration/Components/UsersList/UserRow/BackupOnedrive";
import { humanBytes } from "../../../../utility/humanizers";
import { m365validated } from "../Buttons/Save";
import SlimAlert from "../../../UI2/SlimAlert";

export const UIText = {
  "es-CL": {
    mails: "correos",
    explanation:
      "Para poder guardar un nuevo usuario Microsoft 365, primero debes verificar que el correo que deseas use es válido. La sugerencia ofrecida por el sistema retorna un correo válido en caso de encontrar uno. Adicionalmente, al menos uno de los servicios de respaldo debe estar activado",
  },
  "en-US": {
    mails: "mails",
    explanation:
      "In order to save a new Microsoft 365 user, you must first verify that the email you wish to use is valid. The system's suggestion returns a valid email if it finds one. Additionally, at least one of the backup services must be enabled",
  },
};

export const mailLockId = "365maillock";
export const m365MailId = "365mail";
export const bkpMailSwitchId = "365mailbackup";
export const bkpDriveSwitchId = "365filesbackup";
export const m365setup = "m365setup";

const W365Userform = () => {
  const { dispatch, UI, warehouse, settings } = useUIBoilerplate();
  const { done } = useWorker(getW365ClientId);
  const { done: done2 } = useWorker(m365setup);
  const { done: done3 } = useWorker(getPlatformAndServicesId);
  const open = UI[w365Switch];
  const mailLocked = UI[mailLockId] ?? false;
  const Userfield = UI[userUI] ?? { value: "" };
  const login = Userfield.value;
  const bkpMailCount = UI[bkpMailCountId + login] ?? 0;
  const bkpDriveSize = UI[bkpDriveSizeId + login] ?? 0;
  const { mails, explanation } = UIText[settings.locale];
  const services = useMemo(() => warehouse[platformServices] ?? [], [warehouse])
  const clientData = useMemo(() => warehouse[w365clientData] ?? {}, [warehouse]);
  const isNew = !clientData.client_mail;
  //   console.log("mailLocked", mailLocked);
  //   console.log("clientData done", done);

  const w365org = warehouse[w365orgData] ?? {};

  const own = w365org.own ?? { mail_account: "" };
  const parent = w365org.parent ?? { mail_account: "" };

  // const isRoot = parseInt(group) === auth.group;
  const availableCert = own.client_id ? own : parent;
  const domain = availableCert.mail_account
    ? availableCert.mail_account.split("@")[1]
    : null;

  //   console.log("clientData", clientData);
  //   console.log("w365org", w365org);
  //   console.log("own", own);
  //   console.log("parent", parent);
  const org = {
    id: own.mail_account ? own.id : parent.id,
    domain,
  };

  useEffect(() => {
    if (done && !done2 && done3 && clientData.cloner_user) {
      console.log("clientData", clientData);
      dispatch(
        UISliceActions.setValue({
          id: mailLockId,
          value: true,
        })
      );
      dispatch(
        UISliceActions.setField({
          id: m365MailId + "-" + login,
          value: clientData.client_mail.split("@")[0],
        })
      );
      console.log("services", clientData.services);
      for (let cService of clientData.services) {
        for (let service of services) {
          console.log("setting service", service, cService);
          if (service.id !== cService.service_id) {
            continue
          }
          if (service.name === "Microsoft Mail") {
            console.log("setting service Microsoft Mail");
            dispatch(
              UISliceActions.setValue({
                id: bkpMailSwitchId,
                value: cService.enabled,
              })
            );
          } else if (service.name === "Microsoft Drive") {
            console.log("setting service Microsoft Drive");
            dispatch(
              UISliceActions.setValue({
                id: bkpDriveSwitchId,
                value: cService.enabled,
              })
            );
          }
        }
      }
      dispatch(workersSliceActions.finish({ id: m365setup }));
    }
  }, [dispatch, clientData, done, done2, done3, services, login]);

  return (
    <CollapseGrid
      {...{
        open,
        innerProps: { sx: { paddingBottom: 2, rowGap: 2 } },
      }}
    >
      {isNew ? (
        <SlimAlert
          notCollapse
          {...{ messages: [explanation], severity: "info", xs: 12 }}
        />
      ) : null}
      {isNew ? (
        <Grid container item xs={12} columnSpacing={2}>
          <VerifyMail {...{ org, login }} />
          <SuggestMail {...{ org, login }} />
        </Grid>
      ) : null}

      <Grid container item xs={12} columnSpacing={2}>
        <TextField
          {...{
            id: m365MailId + "-" + login,
            disabled: mailLocked,
            onChange: (e) => {
              dispatch(UISliceActions.clear(m365validated));
            },
            UIText: {
              "es-CL": {
                label: "Correo Microsoft 365",
              },
              "en-US": {
                label: "Microsoft 365 Mail",
              },
            },
            xs: true,
          }}
        />
        <Grid container item xs="auto" sx={{ alignItems: "center" }}>
          @{domain}
        </Grid>
      </Grid>
      <Grid container item xs={12} columnSpacing={2}>
        <Switch
          labelRight
          {...{
            id: bkpMailSwitchId,
            UIText: {
              "es-CL": {
                label: "Respaldar correos",
              },
              "en-US": {
                label: "Backup emails",
              },
            },
            xs: 6,
          }}
        />
        <Grid container item xs={true} sx={{ alignItems: "center" }}>
          <Typography sx={{ fontSize: "0.9rem" }}>{`${
            bkpMailCount ? bkpMailCount : "--"
          } ${mails}`}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} columnSpacing={2}>
        <Switch
          labelRight
          {...{
            id: bkpDriveSwitchId,
            UIText: {
              "es-CL": {
                label: "Respaldar archivos",
              },
              "en-US": {
                label: "Backup files",
              },
            },
            xs: 6,
          }}
        />
        <Grid container item xs={true} sx={{ alignItems: "center" }}>
          <Typography sx={{ fontSize: "0.9rem" }}>
            {humanBytes(bkpDriveSize) ?? "-- bytes"}
          </Typography>
        </Grid>
      </Grid>
    </CollapseGrid>
  );
};

export default W365Userform;
