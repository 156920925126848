import { Fragment, useEffect, useRef } from "react";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import { MoreHoriz } from "@mui/icons-material";
import {
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import MoreActionsButtons from "./MoreActionsButton";

const MoreActions = (props) => {
  const { id, buttonsData = [] } = props;
  const { dispatch, UI } = useUIBoilerplate();
  const ID = `actions-${id}`;
  const open = UI[ID] ?? false;
  const ref = useRef(null);

  const handleToggle = () => {
    // console.log("???");
    dispatch(UIactions.toggle(ID));
  };

  const close = () => {
    dispatch(UIactions.setValue({ id: ID, value: false }));
  };

  const handleClick = (event) => {
    if (ref.current && ref.current.contains(event.target)) {
      return;
    }
    close();
  };

  // Garbage collection
  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(ID));
    };
  }, [dispatch, ID]);

  const buttons = buttonsData.map((data, idx) => {
    if (data.divider) {
      return <Divider key={idx} />;
    } else {
      let onClick;
      if (data.onClick) {
        onClick = () => {
          data.onClick();
          close();
        };
      }

      return <MoreActionsButtons {...data} {...{ key: idx, onClick }} />;
    }
  });

  return (
    <Fragment>
      <IconButton {...{ ref, onClick: handleToggle, size: "small" }}>
        <MoreHoriz sx={{ fontSize: 18 }} />
      </IconButton>
      <Popper
        transition
        {...{
          open,
          anchorEl: ref.current,
          placement: "bottom-end",
          sx: { zIndex: 2000 },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-end" ? "right top" : "right bottom",
            }}
          >
            <Paper elevation={6}>
              <ClickAwayListener onClickAway={handleClick}>
                <MenuList disablePadding>{buttons}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default MoreActions;
