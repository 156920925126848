import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../hooks2/useWorker";
import { UISliceActions } from "../../../store/slices/UI2";
import Button from "../../UI2/Button";
import { rowErrorsUI } from "./UsersList/UserRow/UserRow";
import { bkpMailArrayId } from "./UsersList/UserRow/BackupMail";
import { bkpOneArrayId } from "./UsersList/UserRow/BackupOnedrive";
import { w365orgsData } from "../../../store/actions2/Windows365/getOrganizations";
import { platformServices } from "../../../store/actions2/Windows365/getPlatformsAndServices";
import { postClientsId } from "../../../store/actions2/Windows365/postClients";
import { w365clientsData } from "../../../store/actions2/Windows365/getClients";
import { enqueueSnackbar } from "notistack";

const UIText = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};

const UIText2 = {
  "es-CL": { noChanges: "No hay cambios que guardar" },
  "en-US": { noChanges: "There are no changes to save" },
}

const Create = ({ users }) => {
  const { auth, dispatch, UI, warehouse, settings } = useUIBoilerplate();
  const { W365server, cloner_key } = auth;
  const { locale } = settings;
  const { working } = useWorker(postClientsId);
  const { noChanges } = UIText2[locale];
  const errors = UI[rowErrorsUI] ?? [];
  let orgs = warehouse[w365orgsData] ?? {};
  let services = warehouse[platformServices] ?? [];
  const bkpMails = UI[bkpMailArrayId] ?? [];
  const bkpDrives = UI[bkpOneArrayId] ?? [];
  const clients = warehouse[w365clientsData] ?? {};

  const onClick = () => {
    // console.log("users", users);
    // console.log("selectedUsers", selectedUsers);
    // console.log("bkpMails", bkpMails);
    // console.log("bkpDrive", bkpDrive);
    let payloads = [];

    for (const user of users) {
      let w365mailfield = UI["365mail-" + user.login] ?? {};
      let org = orgs[user.group] ?? {};
      let domain = org.domain.toLowerCase() ?? "";
      let bkpMail = bkpMails.find((x) => x === user.login) ?? "";
      let bkpDrive = bkpDrives.find((x) => x === user.login) ?? "";

      // console.log("user", user);
      // console.log("org", org);
      // console.log("bkpMail", !!bkpMail);
      // console.log("bkpDrive", !!bkpDrive);
      // console.log("services", services);

      let w365servicemail = services.find((x) => {
        if (x.name === "Microsoft Mail") {
          return true;
        }
        return false;
      });
      let w365servicedrive = services.find((x) => {
        if (x.name === "Microsoft Drive") {
          return true;
        }
        return false;
      });

      payloads.push({
        client_mail: w365mailfield.value + "@" + domain,
        organization_id: org.id,
        cloner_user: user.login,
        services: [
          {
            id: w365servicemail.id,
            enabled: !!bkpMail,
          },
          {
            id: w365servicedrive.id,
            enabled: !!bkpDrive,
          },
        ],
      });
    }

    // console.log("payloads", payloads);
    // console.log("clients", clients);
    let payloadsPlus = [];
    for (const payload of payloads) {
      let found = false;
      for (const client of clients) {
        if (payload.cloner_user === client.cloner_user) {
          let post = false;
          // if (
          //   payload.client_mail !== client.client_mail ||
          //   payload.organization_id !== client.organization_id
          // ) {
          //   post = true;
          // }
          for (const service of payload.services) {
            for (const cService of client.services) {
              if (service.id === cService.service_id) {
                // console.log("service match", cService, service);
                if (service.enabled !== cService.enabled) {
                  post = true;
                }
              }
            }
          }
          if (post) {
            payloadsPlus.push({
              client_mail: payload.client_mail,
              organization_id: payload.organization_id,
              cloner_user: payload.cloner_user,
              services: payload.services,
              post: true,
              found: true,
            });
          }
          found = true;
          break;
        }
      }
      if (!found) {
        if (payload.client_mail.split("@")[0] === "undefined" || !payload.client_mail.split("@")[0] ) {
          // console.log("undefined or empty mail");
          continue;
        }
        // let allDisabled = true;
        // // for (const service of payload.services) {
        // //   if (service.enabled) {
        // //     allDisabled = false;
        // //     break;
        // //   }
        // // }
        // if (allDisabled) {
        //   continue
        // }
        payloadsPlus.push({
          client_mail: payload.client_mail,
          organization_id: payload.organization_id,
          cloner_user: payload.cloner_user,
          services: payload.services,
          found: false,
        });
      }
    }

    // console.log("payloadsPlus", payloadsPlus);

    if (!payloadsPlus.length) {
      enqueueSnackbar(noChanges, { variant: "warning" });
      return;
    }

    dispatch(
      UISliceActions.openModal2({
        type: "post-w365-clients-confirmation",
        payload: { payloads: payloadsPlus, cloner_key, W365server, locale},
      })
    );

    // dispatch(postClients({ payloads:payloadsPlus, cloner_key, W365server, locale }));

  };

  const disabled = !!errors.length;

  return <Button {...{ disabled, UIText, onClick, working }} />;
};

export default Create;
