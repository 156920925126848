import { Dialog, Grid } from "@mui/material";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import SlimAlert from "../UI2/SlimAlert";
import BasicDialogContent from "../UI2/BasicDialog/BasicDialogContent";
import BasicDialogTitle from "../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogActions from "../UI2/BasicDialog/BasicDialogActions";
import Button from "../UI2/Button";
import { Download } from "@mui/icons-material";
import { createClientsPDF } from "../../store/actions2/Windows365/createClientsPDF";
import { platformServices } from "../../store/actions2/Windows365/getPlatformsAndServices";

const UIText = {
  "es-CL": { label: "Cerrar" },
  "en-US": { label: "Close" },
};
const UIText2 = {
  "es-CL": { label: "Descargar PDF" },
  "en-US": { label: "Download PDF" },
};

const UITextMessages = {
  "es-CL": {
    header: "Guardado de clientes finalizado",
    message: `El proceso de guardado de clientes finalizó. Le sugerimos descargar el archivo PDF con el resumen del resultado de la operación.`,
  },
  "en-US": {
    header: "Users creation finished",
    message: `The users creation process ended. We suggest that you download the PDF file summarizing the operation.`,
  },
};

const CreateClientsAfterW365Post = (props) => {
  const { dispatch, settings, UI, warehouse } = useUIBoilerplate();
  const { payload } = UI.modal2;
  const services = warehouse[platformServices] ?? [];
  const locale = settings.locale;

  const { header, message } = UITextMessages[locale];

  const onClose = (e, reason) => {
    // if (reason !== "backdropClick") {
    dispatch(UIactions.closeModal2());
    // }
  };

  const onClick = () => {
    dispatch(createClientsPDF({ pdfData: payload.results, services, locale }));
  };

  return (
    <Dialog
      {...{
        open: true,
        onClose,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid container item {...{ sx: { width: "100%" } }}>
          <SlimAlert
            notCollapse
            {...{ errors: [message], severity: "info", xs: 12 }}
          />
          <Grid
            container
            item
            {...{ padding: 3, xs: 12, justifyContent: "center" }}
          >
            <Button
              {...{
                UIText: UIText2,
                onClick,
                variant: "outlined",
                startIcon: <Download />,
                size: "medium",
              }}
            />
          </Grid>
        </Grid>
      </BasicDialogContent>
      <BasicDialogActions>
        <Button {...{ UIText, onClick: onClose }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default CreateClientsAfterW365Post;
